export var urls;
(function (urls) {
    let Profile;
    (function (Profile) {
        Profile.GetProfile = () => `/profile`;
        Profile.PatchProfile = () => `/profile`;
        Profile.PatchProfileFleet = () => `/profile/fleet`;
    })(Profile = urls.Profile || (urls.Profile = {}));
    let Users;
    (function (Users) {
        Users.GetUsers = () => `/users`;
        Users.GetUsersById = (profileId) => `/users/${profileId}`;
        Users.PutUsers = (profileId) => `/users/${profileId}`;
        Users.GetUserRoles = (userId) => `/users/${userId}/roles`;
        Users.PutUserRoles = (userId) => `/users/${userId}/roles`;
    })(Users = urls.Users || (urls.Users = {}));
    let UsersByEmail;
    (function (UsersByEmail) {
        UsersByEmail.GetUsersByEmail = (email) => `/users-by-email/${email}`;
    })(UsersByEmail = urls.UsersByEmail || (urls.UsersByEmail = {}));
    let Scopes;
    (function (Scopes) {
        Scopes.GetScopesList = () => `/scopes`;
    })(Scopes = urls.Scopes || (urls.Scopes = {}));
    let Roles;
    (function (Roles) {
        Roles.GetRoles = () => `/roles`;
        Roles.CreateRole = () => `/roles`;
        Roles.GetRoleDetails = (roleId) => `/roles/${roleId}`;
        Roles.DeleteRole = (roleId) => `/roles/${roleId}`;
        Roles.UpdateRole = (roleId) => `/roles/${roleId}`;
        Roles.GetUsersByRole = (roleId) => `/roles/${roleId}/users`;
        Roles.UpdateRoleUsers = (roleId) => `/roles/${roleId}/users`;
    })(Roles = urls.Roles || (urls.Roles = {}));
    let Context;
    (function (Context) {
        Context.GetContext = () => `/context/scope`;
    })(Context = urls.Context || (urls.Context = {}));
    let Health;
    (function (Health) {
        Health.HealthList = () => `/health`;
    })(Health = urls.Health || (urls.Health = {}));
    let ServiceInfo;
    (function (ServiceInfo) {
        ServiceInfo.ServiceInfoList = () => `/service_info`;
    })(ServiceInfo = urls.ServiceInfo || (urls.ServiceInfo = {}));
    let OrganizationalChart;
    (function (OrganizationalChart) {
        OrganizationalChart.EmployeesCreate = () => `/organizational-chart/employees`;
        OrganizationalChart.TeamsList = () => `/organizational-chart/teams`;
        OrganizationalChart.TeamsCreate = () => `/organizational-chart/teams`;
        OrganizationalChart.TeamsUpdate = (teamId) => `/organizational-chart/teams/${teamId}`;
        OrganizationalChart.GetEmployeesCsv = () => `/organizational-chart/employees-csv`;
    })(OrganizationalChart = urls.OrganizationalChart || (urls.OrganizationalChart = {}));
})(urls || (urls = {}));
